import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import Image from "../../elements/Image";

const Logo = ({ className, ...props }) => {
  const classes = classNames("brand", className);

  return (
    <div {...props} className={classes}>
      <h1 className="m-0">
        <Link to="/">
          <div style={{ flexDirection: "row", display: "flex" }}>
            <Image
              src={require("./../../../assets/images/TIB Logo.jpg")}
              alt="Logo"
              width={64}
              height={64}
              style={{ borderRadius: "50%", marginTop: 20, marginRight: 10 }}
            />
            <Image
              src={require("./../../../assets/images/DWA Logo.png")}
              alt="Logo"
              width={64}
              height={64}
              style={{ borderRadius: "50%", marginTop: 20 }}
            />
          </div>
        </Link>
      </h1>
    </div>
  );
};

export default Logo;
