import React, { useEffect } from "react";
// import sections

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div style={{ paddingTop: 90, marginRight: 10, marginLeft: 10 }}>
      <div>
        <a href="/" style={{ color: "#1354b5" }}>
          {"<<"}back
        </a>
      </div>
      <div>Data Protection Agreement</div>
      <div>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.38",
            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          &nbsp;
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.38",

            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            General notice and mandatory information.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.38",

            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            This privacy policy will explain how our company uses the personal
            data we collect from you when you use our app Dragon Wealth Accounts
            which is a product of The Insane Banana UG.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.38",

            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          &nbsp;
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.38",

            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Designation of the responsible body
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.38",

            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          &nbsp;
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.38",

            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            The responsible body for data processing on this application is:
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.38",

            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            The Insane Banana UG (limited liability)
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.38",

            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Tim Budweg
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.38",

            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Rheinsberger Str. 76/77
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.38",

            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            10115 Berlin
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.38",

            marginTop: "0pt",
            marginBottom: "0pt",
            padding: "0pt 0pt 24pt 0pt",
          }}
        >
          &nbsp;
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.38",

            marginTop: "0pt",
            marginBottom: "0pt",
            padding: "0pt 0pt 24pt 0pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            The responsible body decides alone or jointly with others on the
            purposes and means of processing personal data (e.g. names, contact
            details, etc.).
          </span>
        </p>
        <h3
          dir="ltr"
          style={{
            lineHeight: "1.44",

            marginTop: "0pt",
            marginBottom: "0pt",
            padding: "-10pt 0pt 4pt 0pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            What data do we collect?
          </span>
        </h3>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.38",

            marginTop: "0pt",
            marginBottom: "24pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            The Insane Banana UG collects the following data:
          </span>
        </p>
        <ul
          style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: "48px" }}
        >
          <li
            aria-level={1}
            dir="ltr"
            style={{
              listStyleType: "disc",
              fontSize: "12pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre",
            }}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: "1.7999999999999998",

                marginTop: "0pt",
                marginBottom: "24pt",
              }}
            >
              <span
                style={{
                  fontSize: "12pt",
                  fontFamily: "Arial",
                  color: "#ffffff",
                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Personal identification information (Name, email address, phone
                number, etc.)
              </span>
            </p>
          </li>
        </ul>
        <h3
          dir="ltr"
          style={{
            lineHeight: "1.44",

            marginTop: "14pt",
            marginBottom: "0pt",
            padding: "0pt 0pt 4pt 0pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            How do we collect your data?
          </span>
        </h3>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.38",

            marginTop: "0pt",
            marginBottom: "24pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            You directly provide us with most of the data we collect. We collect
            data and process data when you:
          </span>
        </p>
        <ul
          style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: "48px" }}
        >
          <li
            aria-level={1}
            dir="ltr"
            style={{
              listStyleType: "disc",
              fontSize: "12pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre",
            }}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: "1.7999999999999998",

                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "12pt",
                  fontFamily: "Arial",
                  color: "#ffffff",
                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Register on our app or place an order for any of our products or
                services.
              </span>
            </p>
          </li>
          <li
            aria-level={1}
            dir="ltr"
            style={{
              listStyleType: "disc",
              fontSize: "12pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre",
            }}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: "1.7999999999999998",

                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "12pt",
                  fontFamily: "Arial",
                  color: "#ffffff",
                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Voluntarily complete a customer survey or provide feedback on
                any of our message boards or via email.
              </span>
            </p>
          </li>
          <li
            aria-level={1}
            dir="ltr"
            style={{
              listStyleType: "disc",
              fontSize: "12pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre",
            }}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: "1.7999999999999998",

                marginTop: "0pt",
                marginBottom: "24pt",
              }}
            >
              <span
                style={{
                  fontSize: "12pt",
                  fontFamily: "Arial",
                  color: "#ffffff",
                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Use or view our application via your browser’s cookies.
              </span>
            </p>
          </li>
        </ul>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.7999999999999998",

            marginTop: "0pt",
            marginBottom: "24pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            The application provider automatically collects and stores
            information that your browser automatically transmits to us in
            server log files. These are:
          </span>
        </p>
        <ul
          style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: "48px" }}
        >
          <li
            aria-level={1}
            dir="ltr"
            style={{
              listStyleType: "disc",
              fontSize: "12pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre",
            }}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",

                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "12pt",
                  fontFamily: "Arial",
                  color: "#ffffff",
                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Visited page on our domain
              </span>
            </p>
          </li>
          <li
            aria-level={1}
            dir="ltr"
            style={{
              listStyleType: "disc",
              fontSize: "12pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre",
            }}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",

                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "12pt",
                  fontFamily: "Arial",
                  color: "#ffffff",
                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Date and time of the server request
              </span>
            </p>
          </li>
          <li
            aria-level={1}
            dir="ltr"
            style={{
              listStyleType: "disc",
              fontSize: "12pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre",
            }}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: "1.38",

                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "12pt",
                  fontFamily: "Arial",
                  color: "#ffffff",
                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Browser type and browser version
              </span>
            </p>
          </li>
          <li
            aria-level={1}
            dir="ltr"
            style={{
              listStyleType: "disc",
              fontSize: "12pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre",
            }}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: "1.38",

                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "12pt",
                  fontFamily: "Arial",
                  color: "#ffffff",
                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Operating system used
              </span>
            </p>
          </li>
          <li
            aria-level={1}
            dir="ltr"
            style={{
              listStyleType: "disc",
              fontSize: "12pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre",
            }}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: "1.38",

                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "12pt",
                  fontFamily: "Arial",
                  color: "#ffffff",
                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Referrer URL
              </span>
            </p>
          </li>
          <li
            aria-level={1}
            dir="ltr"
            style={{
              listStyleType: "disc",
              fontSize: "12pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre",
            }}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: "1.38",

                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "12pt",
                  fontFamily: "Arial",
                  color: "#ffffff",
                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Host name of the accessing computer
              </span>
            </p>
          </li>
          <li
            aria-level={1}
            dir="ltr"
            style={{
              listStyleType: "disc",
              fontSize: "12pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre",
            }}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: "1.38",

                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "12pt",
                  fontFamily: "Arial",
                  color: "#ffffff",
                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                IP address
              </span>
            </p>
          </li>
        </ul>
        <h3
          dir="ltr"
          style={{
            lineHeight: "1.44",

            marginTop: "14pt",
            marginBottom: "0pt",
            padding: "0pt 0pt 4pt 0pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            How will we use your data?
          </span>
        </h3>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.38",

            marginTop: "0pt",
            marginBottom: "24pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Our Company collects your data so that we can:
          </span>
        </p>
        <ul
          style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: "48px" }}
        >
          <li
            aria-level={1}
            dir="ltr"
            style={{
              listStyleType: "disc",
              fontSize: "12pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre",
            }}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",

                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "12pt",
                  fontFamily: "Arial",
                  color: "#ffffff",
                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Process your order and manage your account.
              </span>
            </p>
          </li>
          <li
            aria-level={1}
            dir="ltr"
            style={{
              listStyleType: "disc",
              fontSize: "12pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre",
            }}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",

                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "12pt",
                  fontFamily: "Arial",
                  color: "#ffffff",
                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Email you with special offers on other products and services we
                think you might like.
              </span>
            </p>
          </li>
          <li
            aria-level={1}
            dir="ltr"
            style={{
              listStyleType: "disc",
              fontSize: "12pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre",
            }}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",

                marginTop: "0pt",
                marginBottom: "24pt",
              }}
            >
              <span
                style={{
                  fontSize: "12pt",
                  fontFamily: "Arial",
                  color: "#ffffff",
                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Marketing related emails and messages.
              </span>
            </p>
          </li>
        </ul>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.38",

            marginTop: "0pt",
            marginBottom: "0pt",
            padding: "0pt 0pt 24pt 0pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            When Our Company processes your order, it may send your data to, and
            also use the resulting information from, credit reference agencies
            to prevent fraudulent purchases.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.38",

            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Revocation of your consent to data processing
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.38",

            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          &nbsp;
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.38",

            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Some data processing operations are only possible with your express
            consent. You can revoke your already given consent at any time. An
            informal e-mail notification is sufficient for the revocation. The
            legality of the data processing carried out before the revocation
            remains unaffected by the revocation.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.38",

            marginTop: "0pt",
            marginBottom: "0pt",
            padding: "0pt 0pt 24pt 0pt",
          }}
        >
          &nbsp;
        </p>
        <h3
          dir="ltr"
          style={{
            lineHeight: "1.44",

            marginTop: "0pt",
            marginBottom: "0pt",
            padding: "-10pt 0pt 4pt 0pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Marketing
          </span>
        </h3>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.38",

            marginTop: "0pt",
            marginBottom: "0pt",
            padding: "0pt 0pt 24pt 0pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Our Company would like to send you information about products and
            services of ours that we think you might like.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.38",

            marginTop: "0pt",
            marginBottom: "0pt",
            padding: "0pt 0pt 24pt 0pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            You have the right at any time to stop Our Company from contacting
            you for marketing purposes or giving your data to other members of
            the Our Company Group.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.38",

            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Right to lodge a complaint with the competent supervisory authority
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.38",

            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          &nbsp;
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.38",

            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            As a data subject, you have the right to lodge a complaint with the
            competent supervisory authority in the event of a breach of data
            protection law. The competent supervisory authority with regard to
            data protection issues is the state data protection officer of the
            federal state in which our company is based. The following link
            provides a list of data protection officers and their contact
            details:&nbsp;
          </span>
          <a
            href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html"
            style={{ textDecoration: "none" }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#1155cc",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "underline",
                WebkitTextDecorationSkip: "none",
                textDecorationSkipInk: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html
            </span>
          </a>
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            &nbsp;.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.38",

            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          &nbsp;
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.38",

            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          &nbsp;
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.38",

            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Right to data portability
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.38",

            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          &nbsp;
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.38",

            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            You have the right to have data that we process automatically on the
            basis of your consent or in fulfillment of a contract handed over to
            you or to third parties. It is provided in a machine-readable
            format. If you request the direct transfer of the data to another
            person responsible, this will only be done if it is technically
            feasible.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.38",

            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          &nbsp;
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.38",

            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Right to information, correction, blocking, deletion
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.38",

            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          &nbsp;
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.38",

            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            You have the right to free information about your stored personal
            data, the origin of the data, their recipients and the purpose of
            the data processing and, if necessary, the right to correct, block
            or delete this data at any time within the framework of the
            applicable legal provisions. You can contact us at any time using
            the contact options listed in the legal notice if you have any
            further questions on the subject of personal data.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.38",

            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          &nbsp;
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.38",

            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            SSL or TLS encryption
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.38",

            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          &nbsp;
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.38",

            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            For security reasons and to protect the transmission of confidential
            content that you send to us as the site operator, our application
            uses an SSL or. TLS encryption. This means that data that you
            transmit via this application cannot be read by third parties. You
            can recognize an encrypted connection by the "https: //" address
            line of your browser and by the lock symbol in the browser line.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.38",

            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          &nbsp;
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.38",

            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Data protection officer
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.38",

            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          &nbsp;
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.38",

            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            We are neither legally obliged nor have we appointed a data
            protection officer. If you have
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.38",

            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            any questions about data protection, please contact :
            tib@theinsanebanana.com or (030) 23593912
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.38",

            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          &nbsp;
        </p>
        <h3
          dir="ltr"
          style={{
            lineHeight: "1.44",

            marginTop: "0pt",
            marginBottom: "0pt",
            padding: "14pt 0pt 4pt 0pt",
          }}
        >
          &nbsp;
        </h3>
        <h3
          dir="ltr"
          style={{
            lineHeight: "1.44",

            marginTop: "0pt",
            marginBottom: "0pt",
            padding: "10pt 0pt 4pt 0pt",
          }}
        >
          &nbsp;
        </h3>
        <h3
          dir="ltr"
          style={{
            lineHeight: "1.44",

            marginTop: "0pt",
            marginBottom: "0pt",
            padding: "10pt 0pt 4pt 0pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Cookies
          </span>
        </h3>
        <p>
          <br />
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.38",

            marginTop: "0pt",
            marginBottom: "0pt",
            padding: "0pt 0pt 24pt 0pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Cookies are text files placed on your computer to collect standard
            Internet log information and visitor behavior information. When you
            visit our applications, we may collect information from you
            automatically through cookies or similar technology
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.38",

            marginTop: "0pt",
            marginBottom: "0pt",
            padding: "0pt 0pt 24pt 0pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            For further information, visit allaboutcookies.org.
          </span>
        </p>
        <h3
          dir="ltr"
          style={{
            lineHeight: "1.44",

            marginTop: "0pt",
            marginBottom: "0pt",
            padding: "-10pt 0pt 4pt 0pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            How do we use cookies?
          </span>
        </h3>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.38",

            marginTop: "0pt",
            marginBottom: "24pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Our Company uses cookies in a range of ways to improve your
            experience on our application, including:
          </span>
        </p>
        <ul
          style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: "48px" }}
        >
          <li
            aria-level={1}
            dir="ltr"
            style={{
              listStyleType: "disc",
              fontSize: "12pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre",
            }}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: "1.7999999999999998",

                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "12pt",
                  fontFamily: "Arial",
                  color: "#ffffff",
                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Keeping you signed in
              </span>
            </p>
          </li>
          <li
            aria-level={1}
            dir="ltr"
            style={{
              listStyleType: "disc",
              fontSize: "12pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre",
            }}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: "1.7999999999999998",

                marginTop: "0pt",
                marginBottom: "24pt",
              }}
            >
              <span
                style={{
                  fontSize: "12pt",
                  fontFamily: "Arial",
                  color: "#ffffff",
                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Understanding how you use our application
              </span>
            </p>
          </li>
        </ul>
        <h3
          dir="ltr"
          style={{
            lineHeight: "1.44",

            marginTop: "14pt",
            marginBottom: "0pt",
            padding: "0pt 0pt 4pt 0pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            What types of cookies do we use?
          </span>
        </h3>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.38",

            marginTop: "0pt",
            marginBottom: "24pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            There are a number of different types of cookies, however, our
            application uses:
          </span>
        </p>
        <ul
          style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: "48px" }}
        >
          <li
            aria-level={1}
            dir="ltr"
            style={{
              listStyleType: "disc",
              fontSize: "12pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre",
            }}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: "1.7999999999999998",

                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "12pt",
                  fontFamily: "Arial",
                  color: "#ffffff",
                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Functionality – Our Company uses these cookies so that we
                recognize you on our application and remember your previously
                selected preferences. These could include what language you
                prefer and location you are in. A mix of first-party and
                third-party cookies are used.
              </span>
            </p>
          </li>
          <li
            aria-level={1}
            dir="ltr"
            style={{
              listStyleType: "disc",
              fontSize: "12pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre",
            }}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: "1.7999999999999998",

                marginTop: "0pt",
                marginBottom: "24pt",
              }}
            >
              <span
                style={{
                  fontSize: "12pt",
                  fontFamily: "Arial",
                  color: "#ffffff",
                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Advertising – Our Company uses these cookies to collect
                information about your visit to our application, the content you
                viewed, the links you followed and information about your
                browser, device, and your IP address. Our Company sometimes
                shares some limited aspects of this data with third parties for
                advertising purposes. We may also share online data collected
                through cookies with our advertising partners. This means that
                when you visit another application, you may be shown advertising
                based on your browsing patterns on our application.
              </span>
            </p>
          </li>
        </ul>
        <h3
          dir="ltr"
          style={{
            lineHeight: "1.44",

            marginTop: "14pt",
            marginBottom: "0pt",
            padding: "0pt 0pt 4pt 0pt",
          }}
        >
          &nbsp;
        </h3>
        <h3
          dir="ltr"
          style={{
            lineHeight: "1.44",

            marginTop: "0pt",
            marginBottom: "0pt",
            padding: "10pt 0pt 4pt 0pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            How to manage cookies
          </span>
        </h3>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.38",

            marginTop: "0pt",
            marginBottom: "0pt",
            padding: "0pt 0pt 24pt 0pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            You can set your browser not to accept cookies, and the above
            application tells you how to remove cookies from your browser.
            However, in a few cases, some of our application features may not
            function as a result.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.38",

            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Server log files
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.38",

            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          &nbsp;
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.38",

            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            The application provider automatically collects and stores
            information that your browser automatically transmits to us in
            server log files. These are:
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.38",

            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          &nbsp;
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.38",

            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            There is no merging of this data with other data sources. The basis
            for data processing is Article 6 (1) (b) GDPR, which allows the
            processing of data for the fulfillment of a contract or
            pre-contractual measures.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.38",

            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          &nbsp;
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.38",

            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Data transmission when concluding a contract for the purchase and
            dispatch of goods
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.38",

            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          &nbsp;
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.38",

            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Personal data will only be transmitted to third parties if there is
            a need to process the contract. Third parties can be payment service
            providers or logistics companies, for example. A further
            transmission of the data does not take place or only if you have
            expressly consented to this.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.38",

            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          &nbsp;
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.38",

            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            The basis for data processing is Article 6 (1) (b) GDPR, which
            allows the processing of data for the fulfillment of a contract or
            pre-contractual measures.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.38",

            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          &nbsp;
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.38",

            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Google Analytics
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.38",

            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          &nbsp;
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.38",

            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Our application uses functions of the web analysis service Google
            Analytics. The provider of the web analysis service is Google Inc.,
            1600 Amphitheater Parkway, Mountain View, CA 94043, USA.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.38",

            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          &nbsp;
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.38",

            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Google Analytics uses "cookies." These are small text files that
            your web browser saves on your device and that enable application
            usage to be analyzed. Information generated by cookies about your
            use of our application is transmitted to a Google server and stored
            there. The server location is usually the USA.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.38",

            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          &nbsp;
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.38",

            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Google Analytics cookies are set on the basis of Art. 6 Para. 1
            lit.f GDPR. As the operator of this application, we have a
            legitimate interest in analyzing user behavior in order to optimize
            our application and, if necessary, advertising.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.38",

            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          &nbsp;
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.38",

            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            IP anonymization
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.38",

            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          &nbsp;
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.38",

            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            We use Google Analytics in conjunction with the IP anonymization
            function. It ensures that Google shortens your IP address within
            member states of the European Union or in other contracting states
            of the Agreement on the European Economic Area before it is
            transmitted to the USA. There may be exceptional cases in which
            Google transmits the full IP address to a server in the USA and
            abbreviates it there. On our behalf, Google will use this
            information to evaluate your use of the application, to create
            reports on application activity and to provide us with other
            services related to application activity and internet usage. The IP
            address transmitted by Google Analytics is not merged with other
            Google data.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.38",

            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          &nbsp;
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.38",

            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Objection to data collection
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.38",

            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          &nbsp;
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.38",

            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            You can prevent Google Analytics from collecting your data by
            clicking on the following link. An opt-out cookie will be set which
            prevents the collection of your data on future visits to our
            application: Deactivate Google Analytics.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.38",

            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          &nbsp;
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.38",

            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            You can find details on how user data is handled by Google Analytics
            in Google's data protection declaration:
            https://support.google.com/analytics/answer/6004245?hl=de .
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.38",

            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          &nbsp;
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.38",

            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Demographic characteristics in Google Analytics
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.38",

            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          &nbsp;
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.38",

            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Our application uses the “demographic characteristics” function of
            Google Analytics. It can be used to create reports that contain
            statements on the age, gender and interests of the site visitors.
            This data comes from interest-based advertising from Google as well
            as from visitor data from third-party providers. It is not possible
            to assign the data to a specific person. You can deactivate this
            function at any time. You can do this via the ad&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.38",

            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            settings in your Google account or by generally prohibiting the
            collection of your data by Google Analytics, as explained in the
            section “Objection to data collection”.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.38",

            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          &nbsp;
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.38",

            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Mailchimp
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.38",

            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          &nbsp;
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.38",

            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            We use Mailchimp as our email campaign and newsletter tool. You can
            find details on how contacts data is handled by Mailchimp in
            Mailchimp's data protection declaration:
            https://mailchimp.com/legal/privacy/#3._Privacy_for_Contacts
          </span>
        </p>
        <p>
          <br />
        </p>
      </div>
    </div>
  );
};

export default Home;
