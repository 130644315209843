import React from "react";
import Image from "../components/elements/Image";

const DWA = () => {
  return (
    <div style={{ paddingTop: 90, marginRight: 10, marginLeft: 10 }}>
      <div>
        <a href="/" style={{ color: "#1354b5" }}>
          {"<<"}back to main page
        </a>
      </div>
      <div>
        <p>The Dragon Wealth Accounts process</p>
        <p
          dir="ltr"
          style={{ lineHeight: "1.38", marginTop: "0pt", marginBottom: "0pt" }}
        >
          <span
            style={{
              fontSize: "11pt",

              color: "#ffffff",
              backgroundColor: "transparent",

              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            First, I want to let you know about the process which is designed to
            get you into the habit of managing your money. This is so important
            because we humans are creatures of habit. So, first understand that
            managing your money is a crucial step to becoming wealthy. Whether
            we learn it with 100€ or 10 000€ does not matter too much. Don't
            worry if your income is low currently. Do it anyway.&nbsp;
          </span>
        </p>
        <p>
          <br />
        </p>
        <p
          dir="ltr"
          style={{ lineHeight: "1.38", marginTop: "0pt", marginBottom: "0pt" }}
        >
          <span
            style={{
              fontSize: "11pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            The universe always provides the perfect opportunity to experience
            what your soul wants to experience. And if you cannot handle "a lot"
            of money yet because you would blow it all, first you need to
            "prove" that you can do it with less money. Accept this gift and
            understand that you get all the support you ever need! It might come
            in form of anything around you. A billboard saying "you can do it"
            or in form of other people like in this case where we are helping
            you.
          </span>
        </p>
        <p>
          <br />
        </p>
        <p
          dir="ltr"
          style={{ lineHeight: "1.38", marginTop: "0pt", marginBottom: "0pt" }}
        >
          <span
            style={{
              fontSize: "11pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Now, that you know WHY you can decide to take this app seriously and
            balance your money, let's get into it.
          </span>
        </p>
        <p
          dir="ltr"
          style={{ lineHeight: "1.38", marginTop: "0pt", marginBottom: "0pt" }}
        >
          <span
            style={{
              fontSize: "11pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Everytime you buy something you simply make a small entry in the app
            stating what you bought and to which ACCOUNT it belongs.
          </span>
        </p>
        <p
          dir="ltr"
          style={{ lineHeight: "1.38", marginTop: "0pt", marginBottom: "0pt" }}
        >
          <span
            style={{
              fontSize: "11pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            For example I went to a grocery shop and bought my food for the
            weekend. It cost 23.85€.
          </span>
        </p>
        <p
          dir="ltr"
          style={{ lineHeight: "1.38", marginTop: "0pt", marginBottom: "0pt" }}
        >
          <span
            style={{
              fontSize: "11pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Then, I create an entry in the app by&nbsp;
          </span>
          <span
            style={{
              fontSize: "11pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            tapping the blue add button
          </span>
          <span
            style={{
              fontSize: "11pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            . Choose&nbsp;
          </span>
          <span
            style={{
              fontSize: "11pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Expense
          </span>
          <span
            style={{
              fontSize: "11pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            , put a quick note "
          </span>
          <span
            style={{
              fontSize: "11pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Groceries
          </span>
          <span
            style={{
              fontSize: "11pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            " and choose the account&nbsp;
          </span>
          <span
            style={{
              fontSize: "11pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Necessities
          </span>
          <span
            style={{
              fontSize: "11pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            .
          </span>
        </p>
        <p
          dir="ltr"
          style={{ lineHeight: "1.38", marginTop: "0pt", marginBottom: "0pt" }}
        >
          <span
            style={{
              fontSize: "11pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            After hitting&nbsp;
          </span>
          <span
            style={{
              fontSize: "11pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            add&nbsp;
          </span>
          <span
            style={{
              fontSize: "11pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            it will show up on your home screen and that's it.
          </span>
        </p>
        <p
          dir="ltr"
          style={{ lineHeight: "1.38", marginTop: "0pt", marginBottom: "0pt" }}
        >
          <span
            style={{
              fontSize: "11pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            (pro tip: you can add entries for past dates by tapping the date in
            the top right corner)
          </span>
        </p>
        <p>
          <br />
        </p>
        <p
          dir="ltr"
          style={{ lineHeight: "1.38", marginTop: "0pt", marginBottom: "0pt" }}
        >
          <span
            style={{
              fontSize: "11pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Congrats! You just tracked your first expense! In no time you are a
            millionaire money manager!
          </span>
        </p>
        <p>
          <br />
        </p>
        <p
          dir="ltr"
          style={{ lineHeight: "1.38", marginTop: "0pt", marginBottom: "0pt" }}
        >
          <span
            style={{
              fontSize: "11pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            So, now you might wonder what "
          </span>
          <span
            style={{
              fontSize: "11pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Necessities
          </span>
          <span
            style={{
              fontSize: "11pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            " is. It is one of 6 of your new money acconuts. From now on you
            will distribute every income you have into these 6 different
            accounts and when you have an expense you pay out of one of these
            accounts. In the example above we paid our grocery trip from the
            account "Necessities" since it is a necessity to your desired
            lifestyle.
          </span>
        </p>
        <p
          dir="ltr"
          style={{ lineHeight: "1.38", marginTop: "0pt", marginBottom: "0pt" }}
        >
          <span
            style={{
              fontSize: "11pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Easy, right?
          </span>
        </p>
        <p>
          <br />
        </p>
        <p
          dir="ltr"
          style={{ lineHeight: "1.38", marginTop: "0pt", marginBottom: "0pt" }}
        >
          <span
            style={{
              fontSize: "11pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Let's take a look at all accounts:
          </span>
        </p>

        <p>
          <Image
            src={require("./../assets/images/accountsOverview.png")}
            alt="Features split 01"
            width={528}
            height={396}
          />
        </p>

        <ol
          style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: "48px" }}
        >
          <li
            aria-level={1}
            dir="ltr"
            style={{
              listStyleType: "decimal",
              fontSize: "11pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre",
            }}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: "1.38",
                marginTop: "12pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Arial",
                  color: "#ffffff",
                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Necessities&nbsp;
              </span>
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Arial",
                  color: "#ffffff",
                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                - The once you already got to know - you pay anything from here
                what you need for your desired lifestyle. Examples: Groceries,
                rent, health insurance
              </span>
            </p>
          </li>
          <li
            aria-level={1}
            dir="ltr"
            style={{
              listStyleType: "decimal",
              fontSize: "11pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre",
            }}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: "1.38",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Arial",
                  color: "#ffffff",
                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Fun&nbsp;
              </span>
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Arial",
                  color: "#ffffff",
                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                - everything you REALLY want to do or have (in your dream life).
                Examples: relaxing massage, stay in a 5* hotel, video games
              </span>
            </p>
          </li>
          <li
            aria-level={1}
            dir="ltr"
            style={{
              listStyleType: "decimal",
              fontSize: "11pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre",
            }}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: "1.38",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Arial",
                  color: "#ffffff",
                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Education&nbsp;
              </span>
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Arial",
                  color: "#ffffff",
                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                - everything you spent for your personal education. Examples:
                self-help &amp; personal development books, seminars, online
                courses to learn something
              </span>
            </p>
          </li>
          <li
            aria-level={1}
            dir="ltr"
            style={{
              listStyleType: "decimal",
              fontSize: "11pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre",
            }}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: "1.38",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Arial",
                  color: "#ffffff",
                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Donation&nbsp;
              </span>
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Arial",
                  color: "#ffffff",
                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                - the money you give away while not expecting any return (Why?
                True joy is in selfless giving) Examples: Donation to any
                charity
              </span>
            </p>
          </li>
          <li
            aria-level={1}
            dir="ltr"
            style={{
              listStyleType: "decimal",
              fontSize: "11pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre",
            }}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: "1.38",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Arial",
                  color: "#ffffff",
                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Long term savings for spending
              </span>
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Arial",
                  color: "#ffffff",
                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                &nbsp;(LTSS) - Out of this account you pay for your big dreams.
                Examples: amazing vacation on Hawaii, Mavic drone, Canyon road
                bike
              </span>
            </p>
          </li>
          <li
            aria-level={1}
            dir="ltr"
            style={{
              listStyleType: "decimal",
              fontSize: "11pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre",
            }}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: "1.38",
                marginTop: "0pt",
                marginBottom: "12pt",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Arial",
                  color: "#ffffff",
                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Financial Freedom
              </span>
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Arial",
                  color: "#ffffff",
                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                &nbsp;(FFA) - this account holds the money which you invest with
                the purpose of growing. You never spend money out of here unless
                it is for said purpose.
              </span>
            </p>
          </li>
        </ol>
        <p
          dir="ltr"
          style={{ lineHeight: "1.38", marginTop: "0pt", marginBottom: "0pt" }}
        >
          <span
            style={{
              fontSize: "11pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            So, I know this is a lot to digest at once. But you must have heard
            it once so you know what will happen in the next days. We help you
            engrain this concept into your brain and if you follow it, you will
            become wealthy and financially free!
          </span>
        </p>
        <p>
          <br />
        </p>
        <p
          dir="ltr"
          style={{ lineHeight: "1.38", marginTop: "0pt", marginBottom: "0pt" }}
        >
          <span
            style={{
              fontSize: "11pt",
              fontFamily: "Arial",
              color: "#ffffff",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            I know this because my coach taught me this and countless other financial professionals do
            teach it as well (millionaires simply do it;-)). I did not invent this:D I just built
            the app because I wanted it to have for me and I am extremely
            convinced that it can help everyone to become financially free
            eventually. Remember, the habit is more important than the amount
            you put into it. If you are skeptical, don't do it with all your
            money, just use 100€ first and give it a go for a month. (Though, I
            highly encourage you to do it with all your income;-) Prove the
            universe you can handle EVERY cent which comes to you)
          </span>
        </p>
        <p>
          <br />
        </p>
      </div>
    </div>
  );
};

export default DWA;
