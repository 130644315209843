import React, { useState } from 'react';
import MobileStoreButton from '../MobileStoreButton';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';
import Image from '../elements/Image';
import Modal from '../elements/Modal';

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const [videoModalActive, setVideomodalactive] = useState(false);

  const openModal = (e) => {
    e.preventDefault();
    setVideomodalactive(true);
  };

  const closeModal = (e) => {
    e.preventDefault();
    setVideomodalactive(false);
  };

  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className,
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider',
  );

  return (
    <section {...props} className={outerClasses}>
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
            <h1
              className="mt-0 mb-16 reveal-from-bottom"
              data-reveal-delay="000"
            >
              Dragon Wealth Accounts
            </h1>
            <p className="reveal-from-bottom" data-reveal-delay="400">
              Level up your money IQ
            </p>
            <div className="container-xs">
              <p
                className="m-0 mb-32 reveal-from-bottom"
                data-reveal-delay="400"
              >
                Organize your money into accounts to handle money like a
                millionaire while keeping your money in your one bankaccount.
              </p>
              <div className="reveal-from-bottom" data-reveal-delay="600">
                <div className="">
                  <MobileStoreButton
                    store="ios"
                    url={
                      'https://apps.apple.com/us/app/dragon-wealth-accounts/id1484527980'
                    }
                    linkProps={{ title: 'iOS Store Button' }}
                    linkStyles={{
                      display: 'inline-block',
                      overflow: 'hidden',
                      textDecoration: 'none',
                      height: '100%',
                      width: '100%',
                      padding: '5px',
                      backgroundSize: '255px 74px',
                      backgroundPosition: 'center',
                      backgroundRepeat: 'no-repeat',
                    }}
                  />
                  <MobileStoreButton
                    store="android"
                    url={
                      'https://play.google.com/store/apps/details?id=com.bonscanner'
                    }
                    linkProps={{ title: 'Android Play store' }}
                    linkStyles={{
                      display: 'inline-block',
                      overflow: 'hidden',
                      textDecoration: 'none',
                      height: '100%',
                      width: '100%',
                      padding: '5px',
                      backgroundSize: '287px 112px',
                      backgroundPosition: 'center',
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className="hero-figure reveal-from-bottom illustration-element-01"
            data-reveal-value="20px"
            data-reveal-delay="800"
          >
            {/* <a
              data-video="https://player.vimeo.com/video/174002812"
              href="#0"
              aria-controls="video-modal"
              onClick={openModal}
            > */}
            <Image
              className="has-shadow"
              src={require('./../../assets/images/video-placeholder.jpg')}
              alt="Hero"
              width={896}
              height={504}
            />
            {/* </a> */}
          </div>
          {/* <Modal
            id="video-modal"
            show={videoModalActive}
            handleClose={closeModal}
            video="https://player.vimeo.com/video/174002812"
            videoTag="iframe"
          /> */}
        </div>
      </div>
    </section>
  );
};

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;
