import React from "react";
// import sections
import Hero from "../components/sections/Hero";
import FeaturesTiles from "../components/sections/FeaturesTiles";
import FeaturesSplit from "../components/sections/FeaturesSplit";
import Testimonial from "../components/sections/Testimonial";
import Cta from "../components/sections/Cta";
import SectionHeader from "../components/sections/partials/SectionHeader";

const Home = () => {
  return (
    <>
      <Hero className="illustration-section-01" />
      <FeaturesTiles />
      <SectionHeader
        data={{
          title: "How does it work?",
          paragraph:
            "Find a detailed explanation of the Dragon Wealth Accounts model",
          linkText: "here",
          linkDestination: "/DWA",
        }}
        className="center-content"
      />
    </>
  );
};

export default Home;
