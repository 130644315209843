import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

import fastSVG from '../../assets/images/fast.svg';
import easyTrackingSVG from '../../assets/images/easy-tracking.svg';
import easyToUseSVG from '../../assets/images/easy-to-use.svg';
import accountsSVG from '../../assets/images/accounts.svg';

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};
const FeaturesTiles = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className,
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner pt-0',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider',
  );

  const tilesClasses = classNames(
    'tiles-wrap center-content',
    pushLeft && 'push-left',
  );

  const sectionHeader = {
    title: 'Why?',
    paragraph:
      'I developed this app to have a flexible method of tracking and organizing my money. Most of the techniques I got to know were too rigid ' +
      'and I ended up not having the amount of money in my account as I should. Or they were too complicated (GnuCash...) to make any sense in my everday life. ' +
      'Dragon Wealth Accounts solved that for me.',
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>
            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div
                    className="features-tiles-item-image mb-16"
                    style={{ width: 64, height: 64 }}
                  >
                    <Image
                      src={easyTrackingSVG}
                      alt="Features tile icon 01"
                      width={52}
                      height={52}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Easy Tracking</h4>
                  <p className="m-0 text-sm">
                    Combines flexibility with usefulness so you always know how
                    much money you have for what purpose.
                  </p>
                </div>
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="200"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div
                    className="features-tiles-item-image mb-16"
                    style={{ width: 64, height: 64 }}
                  >
                    <Image
                      src={accountsSVG}
                      alt="Features tile icon 02"
                      width={45}
                      height={45}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Accounts</h4>
                  <p className="m-0 text-sm">
                    Split your money into 6 different accounts for an amazing
                    overview of your money
                  </p>
                </div>
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="400"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div
                    className="features-tiles-item-image mb-16"
                    style={{ width: 64, height: 64 }}
                  >
                    <Image
                      src={easyToUseSVG}
                      alt="Features tile icon 03"
                      width={45}
                      height={45}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">No hassle</h4>
                  <p className="m-0 text-sm">
                    Since you only need to manage one real bankaccount the
                    paperwork or making real transactions with pin and tan's is
                    minimized
                  </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div
                    className="features-tiles-item-image mb-16"
                    style={{ width: 64, height: 64 }}
                  >
                    <Image
                      src={fastSVG}
                      alt="Features tile icon 04"
                      width={45}
                      height={45}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Instant and Up-to-date</h4>
                  <p className="m-0 text-sm">
                    All transactions are instant and therefore, you do not need
                    to wait and buy nothing for a few days to see how much money
                    you really have left
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;
